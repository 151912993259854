var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mx-auto" },
    [
      _c("h2", { staticClass: "mt-4" }, [_vm._v("分析ツール")]),
      _c("div", { staticClass: "w-full mt-4" }, [
        _c("label", { staticClass: "mr-2", attrs: { for: "range" } }, [
          _vm._v("分析期間の選択"),
        ]),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.months,
                expression: "months",
              },
            ],
            staticClass: "h-10 bg-white border rounded-md border-gray-400",
            attrs: { id: "range" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.months = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                function ($event) {
                  return _vm.getLogs()
                },
              ],
            },
          },
          _vm._l([1, 3, 6], function (index) {
            return _c(
              "option",
              {
                key: index,
                domProps: { value: index, selected: index === _vm.months },
              },
              [_vm._v(_vm._s(index) + "ヶ月間")]
            )
          }),
          0
        ),
      ]),
      _vm._m(0),
      _c("div", { staticClass: "grid grid-cols-4 gap-4 mt-4" }, [
        _c("div", { staticClass: "rounded-md border shadow p-2 col-span-2" }, [
          _c("p", { staticClass: "text-xs" }, [
            _vm._v(
              "御社ページへの直近" +
                _vm._s(_vm.months) +
                "ヶ月のアクセスユーザー数（延べ数の合算）"
            ),
          ]),
          _c("p", { staticClass: "font-bold text-center" }, [
            _c("span", { staticClass: "text-7xl font-bold" }, [
              _vm._v(_vm._s(_vm.logsMeta.total)),
            ]),
            _c("span", { staticClass: "text-lg font-bold" }, [
              _vm._v("ユーザー"),
            ]),
          ]),
          _c("p", { staticClass: "text-xs" }, [
            _vm._v("御社ページへの直近" + _vm._s(_vm.months) + "ヶ月の占有率"),
          ]),
          _c("p", { staticClass: "font-bold text-center" }, [
            _c("span", { staticClass: "text-7xl font-bold" }, [
              _vm._v(_vm._s(_vm.percentage)),
            ]),
            _c("span", { staticClass: "text-lg font-bold mr-14" }, [
              _vm._v("%"),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass:
                "bg-gray-100 rounded-md p-3 m-2 bottom-2 right-2 left-2 text-xs",
            },
            [
              _c("p", [
                _vm._v(
                  "アクセス数が少ない場合には、まずは、フィルミネーション内のトラフィックを御社の作品に誘引する方策をおためしください。"
                ),
              ]),
              _c("p", { staticClass: "mt-2" }, [_vm._v("具体的には:")]),
              _vm._m(1),
              _c("p", { staticClass: "mt-2" }, [
                _vm._v(
                  "その他、なんらかのアワードを受賞されている場合には、かならず、登録をお願いします。"
                ),
              ]),
              _c("p", [
                _vm._v(
                  "バイヤーの検索方法には、「受賞作品のみ」というスイッチがあるためです。"
                ),
              ]),
              _c("p", [
                _vm._v(
                  "サムネイル画像を下記などを参考に再検討してみてください。"
                ),
              ]),
              _vm._m(2),
              _c("p", { staticClass: "mt-2" }, [
                _vm._v("A・B実験を行うのもお勧めです。"),
              ]),
              _c("p", [
                _vm._v(
                  "アクセス数が減少している場合には、オンラインプロモーションによって集客をすることをご検討ください。"
                ),
              ]),
              _c("p", [
                _vm._v(
                  "最も、効果的なのは、メールマーケティングです。ご希望があればまずはご相談ください。"
                ),
              ]),
              _c("p", { staticClass: "mb-4 text-xs" }, [
                _vm._v(
                  "※占有率は、オンライフィルムマーケット全体のアクセスに対し御社のアクセスが占める割合を示します。"
                ),
              ]),
              _c("div", { staticClass: "flex justify-end" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "h-full px-4 py-3 rounded font-bold bg-off-yellow hover:bg-off-yellow-lighter active:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed flex-none",
                    on: {
                      click: function ($event) {
                        return _vm.sendRequestToSlack("メールマーケティング")
                      },
                    },
                  },
                  [_vm._v("メールマーケティングに申し込む")]
                ),
              ]),
            ]
          ),
        ]),
        _c(
          "div",
          { staticClass: "rounded-md border shadow p-2 col-span-2 relative" },
          [
            _c("p", { staticClass: "text-xs" }, [
              _vm._v("アクセス元の国（バイヤーが登録しているものだけ）"),
            ]),
            _c(
              "div",
              { staticClass: "mx-12" },
              [_c("FlMap", { attrs: { countries: _vm.countries } })],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "bg-gray-100 rounded-md p-3 m-2 text-xs right-2 bottom-2 left-2",
              },
              [
                _c("p", [
                  _vm._v(
                    "特定の国からのアクセスが多い場合には、その国の言語の字幕をつくることをお勧めいたします。"
                  ),
                ]),
                _c("p", [_vm._v("AI翻訳がおすすめです。")]),
                _c("p", [
                  _vm._v(
                    "特に、英語字幕をお持ちならば、ドイツ語などヨーロッパの言語への変換はかなり精度が高くなります。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "ご用意がある場合には、作品詳細ページに、ご登録をおねがいいたします。なお、字幕につきましては、Srt形式のファイルを用意するのが最も有効です。ご用意がある場合には、作品詳細ページに、ご登録をおねがいいたします。"
                  ),
                ]),
                _c("div", { staticClass: "flex justify-end mt-2" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "h-full px-4 py-3 rounded font-bold bg-off-yellow hover:bg-off-yellow-lighter active:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed flex-none mr-2",
                      on: {
                        click: function ($event) {
                          return _vm.sendRequestToSlack("AI翻訳")
                        },
                      },
                    },
                    [_vm._v("AI翻訳に申し込む")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass:
                        "h-full px-4 py-3 rounded font-bold bg-off-yellow hover:bg-off-yellow-lighter active:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed flex-none",
                      on: {
                        click: function ($event) {
                          return _vm.sendRequestToSlack("SRTファイル作成")
                        },
                      },
                    },
                    [_vm._v("SRTファイル作成に申し込む")]
                  ),
                ]),
              ]
            ),
          ]
        ),
        _c("div", { staticClass: "rounded-md border shadow p-2 col-span-1" }, [
          _c("p", { staticClass: "text-xs" }, [
            _vm._v("バイヤーのカートに入っている延べ作品数"),
          ]),
          _c("p", { staticClass: "text-center" }, [
            _c("span", { staticClass: "text-7xl font-bold" }, [
              _vm._v(_vm._s(_vm.carts.length)),
            ]),
            _c("span", { staticClass: "text-lg font-bold" }, [_vm._v("件")]),
          ]),
          _c("div", { staticClass: "bg-gray-100 rounded-md p-3 m-2 text-xs" }, [
            _c("p", [
              _vm._v(
                "カートに入っている場合は、バイヤーが御社の作品に興味をもっている証拠で、検討中のことが多いです。この場合には、IMDbの作品ページの整備がお勧めです。IMDbの情報きちんと入っている作品が選ばれやすいことが分かっております。"
              ),
            ]),
            _c("p", [
              _vm._v(
                "ご自身での修正などが難しい場合には、弊社の入力代行サービスにご相談ください。"
              ),
            ]),
            _c("p", [
              _vm._v(
                "カートに作品が入っていない場合には、アクセスを増やす対策を行ってください。"
              ),
            ]),
            _c("p", [_vm._v("詳細は、アクセス数の項目をご確認ください。")]),
            _c("div", { staticClass: "flex items-end mt-4" }, [
              _c(
                "button",
                {
                  staticClass:
                    "ml-auto mr-0 h-full px-4 py-3 rounded text-center font-bold bg-off-yellow hover:bg-off-yellow-lighter active:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed flex-none",
                  on: {
                    click: function ($event) {
                      return _vm.sendRequestToSlack("IMDb登録サービス")
                    },
                  },
                },
                [_vm._v("IMDb登録サービスに申し込む")]
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "rounded-md border shadow p-2 col-span-3" }, [
          _vm._m(3),
          _c("div", { staticClass: "grid grid-cols-1" }, [
            _c("div", [
              _c("p", { staticClass: "text-xs text-center" }, [
                _vm._v("固定売り上げ分"),
              ]),
              _c("p", { staticClass: "text-4xl font-bold text-center" }, [
                _c("span", { staticClass: "text-4xl" }, [
                  _vm._v(_vm._s(_vm._f("price")(_vm.totalFlatPrice, "JPY"))),
                ]),
              ]),
            ]),
            _c("div", [
              _c("p", { staticClass: "text-xs text-center" }, [
                _vm._v("レベニューシェアMG分"),
              ]),
              _c("p", { staticClass: "font-bold text-center" }, [
                _c("span", { staticClass: "text-4xl" }, [
                  _vm._v(_vm._s(_vm._f("price")(_vm.totalMGPrice, "JPY"))),
                ]),
              ]),
            ]),
            _c("div", [
              _c("p", { staticClass: "text-xs text-center" }, [
                _vm._v("レベニューシェア分（想定値）"),
              ]),
              _c("p", { staticClass: "font-bold text-center" }, [
                _c("span", { staticClass: "text-4xl" }, [
                  _vm._v(
                    _vm._s(_vm._f("price")(_vm.totalAverageRSPrice, "JPY"))
                  ),
                ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass:
                "bg-gray-100 rounded-md p-3 m-2 bottom-2 right-2 left-2 text-xs",
            },
            [
              _c("p", [_vm._v("さらに売り上げをあげるためには。")]),
              _c("p", [
                _vm._v(
                  "作品詳細ページの整備、IMDbページの編集、プロモーションによる集客などをすでに行っている場合には、AmazonUSへの申請がおすすめです。"
                ),
              ]),
              _c("p", [
                _vm._v(
                  "理由としては、「chain of title」のご用意がない作品では、 AmazonUSでの配信実績がその代替となる場合があるためです。"
                ),
              ]),
              _c("p", [
                _vm._v("ご希望があれば、右のボタンよりご相談ください。"),
              ]),
              _c("p", { staticClass: "text-xxs" }, [
                _vm._v(
                  "※ chain of titleにつきましては、用語集などをご参照ください。"
                ),
              ]),
              _c("div", { staticClass: "w-full text-right" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "ml-auto h-full px-4 py-3 rounded text-center font-bold bg-off-yellow hover:bg-off-yellow-lighter active:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed flex-none",
                    on: {
                      click: function ($event) {
                        return _vm.sendRequestToSlack("Amazon申請代行")
                      },
                    },
                  },
                  [_vm._v(" Amazon申請代行に申し込む ")]
                ),
              ]),
            ]
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "w-full rounded-md border shadow p-2 mt-4" },
        [_c("AccessChart", { attrs: { logs: _vm.logs } })],
        1
      ),
      _c(
        "div",
        {
          staticClass: "bg-gray-100 rounded-md p-3 my-4 text-sm flex items-end",
        },
        [
          _vm._m(4),
          _c(
            "button",
            {
              staticClass:
                "mx-auto px-4 h-full py-3 rounded text-center font-bold bg-off-yellow hover:bg-off-yellow-lighter active:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed flex-none text-xs",
              on: {
                click: function ($event) {
                  return _vm.sendRequestToSlack("営業フォロー")
                },
              },
            },
            [_vm._v(" 営業のフォローに申し込む ")]
          ),
        ]
      ),
      _c("AccessLogTable", { attrs: { logs: _vm.logs, isSingle: false } }),
      _c("h2", { staticClass: "mt-10" }, [
        _vm._v("新規登録作品の自動紹介機能"),
      ]),
      _vm._m(5),
      _c("h2", { staticClass: "mt-10" }, [
        _vm._v("納品したデータのダウンロード機能"),
      ]),
      _vm._m(6),
      _c("h2", { staticClass: "mt-10" }, [_vm._v("保管庫機能")]),
      _vm._m(7),
      _c("NoticeModal", {
        attrs: {
          modalName: "sentPremiumRequestModal",
          title: "申し込みを送信しました",
          contents: "運営からのご連絡をお待ちください。",
          buttons: ["OK"],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "bg-gray-100 rounded-md p-2 my-4 text-sm" },
      [
        _c("p", [
          _vm._v(
            "この分析ツールでは、どの海外バイヤーが、御社のどの作品のページにいつアクセスしているかや、御社のどの作品がカートに入っている状況などを、1ヶ月から最大6か月分まで閲覧していただくことができます。"
          ),
        ]),
        _c("p", [
          _vm._v(
            "この情報をもとに、適切なアクションを行うことで、販売確率をあげることができます。"
          ),
        ]),
        _c("p", [
          _vm._v(
            "詳しくは各モジュールに記載しましたので、ご確認ください。また、閲覧したいデータがありましたら、お問い合わせフォームからリクエストください、追加を検討させていただきます。"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "list-disc ml-3" }, [
      _c("li", [
        _vm._v(
          "検索にひっかかるように商品詳細ページの詳細情報の「タグ」の選択を設定してください。"
        ),
      ]),
      _c("li", [_vm._v("同じく、ジャンルを増やしてみてください。")]),
      _c("li", [
        _vm._v(
          "同じく、PRコメントを増やしてみてください。有名な方が関係者でいる場合には、お名前をここに挿入ください。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "list-disc ml-3 mt-2" }, [
      _c("li", [_vm._v("インパクトがあるか？")]),
      _c("li", [_vm._v("登場人物の顔がはいっているか？")]),
      _c("li", [_vm._v("タイトルロゴが、英語で作成されているか？")]),
      _c("li", [_vm._v("タイトルロゴが読みずらくないか？")]),
      _c("li", [_vm._v("不自然な圧縮がはいっていないか？")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-xs mb-4" }, [
      _c("span", [_vm._v("購入確定分の売り上げ")]),
      _c("span", { staticClass: "text-xxs" }, [
        _vm._v("（ただし、レベニューシェア分に関しては想定値となります）"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mr-3" }, [
      _c("span", [
        _vm._v(
          "バイヤー名をクリックすると、バイヤーが入力している会社やサービス情報を確認いただくことができます。"
        ),
      ]),
      _c("span", [
        _vm._v(
          "作品名をクリックすると、各作品のアクセスデータを閲覧いただけます。"
        ),
      ]),
      _c("span", [
        _vm._v(
          "特定のバイヤーが何度も閲覧している場合には、弊社の営業担当がフォローを行うことも可能です。「特定の作品に関する個別営業サービス」になります。"
        ),
      ]),
      _c("span", [
        _vm._v("ご希望があれば、こちらのボタンからご相談ください。"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bg-gray-100 rounded-md p-3 my-4" }, [
      _c("p", [
        _vm._v(
          "新規登録作品自動紹介機能：新規にご登録いただいた作品を、弊社の全てのお客様に配信する自動メールに掲載することで、アクセス数UPにつながります。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bg-gray-100 rounded-md p-3 my-4" }, [
      _c("p", [
        _vm._v(
          "オンライフィルムマーケット上にアップロードし保存してあるファイルを、ダウンロードできます。"
        ),
      ]),
      _c("p", [_vm._v("ダウンロードは納品ページからお願いいたします。")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bg-gray-100 rounded-md p-3 my-4" }, [
      _c("p", [
        _vm._v(
          "一度アップいただいた作品のデータが、作品の契約が終わってもオンライフィルムマーケット上にアップロードし保存してあるファイルが削除されなくなっております。"
        ),
      ]),
      _c("p", [
        _vm._v(
          "ダウンロード機能と合わせて、データの素材の保管庫としてご活用ください。"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }